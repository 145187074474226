import React from 'react';
import { Typography } from '@mui/material';

const ErrorPage = () => {

    return (
        <Typography>Error</Typography>
    )
}

export default ErrorPage;